<template>
  <form @submit.prevent="createIndexes">
    <section class="modal-card-body">
      <div v-if="error" class="notification is-danger">
        <ul>
          <li v-for="message in errorMsg" :key="message">{{ message }}</li>
        </ul>
      </div>
      <div class="field">
        <label for="date" class="label">
          {{ $t("monitor.index.form.fields.date.label") }}
        </label>
        <p class="control">
          <input
            name="date"
            :disabled="isLoading"
            v-model="form.date"
            @change="($event) => retriveIndexInstance($event)"
            class="input"
            type="date"
            required
            :placeholder="$t('monitor.index.form.fields.date.placeholder')"
          />
        </p>
      </div>
      <div
        class="field"
        v-for="kind in selectedMeter.meterStructure.dataKinds"
        :key="kind"
      >
        <label for="value" class="label">
          {{ $t("monitor.index.form.fields.value.label") }}
          {{ kind }} ({{ selectedMeter.meterStructure.unit }})
        </label>
        <p class="control">
          <input
            name="value"
            :value="form.indexes[kind].value"
            @input="updateFormValue(kind, $event.target.value)"
            :disabled="isLoading"
            class="input"
            type="number"
            :placeholder="$t('monitor.index.form.fields.value.placeholder')"
          />
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="button is-rounded" @click="closeModal">
        {{ $t("global.buttons.back") }}
      </div>
      <div
        class="button is-success is-rounded"
        :disabled="!canAdd"
        @click="createIndexes"
      >
        {{ $t("global.buttons.add") }}
      </div>
    </footer>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CreateUdateIndex",
  props: {
    initData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        indexes: {},
        date: null,
      },
      isLoading: false,
      error: false,
      errorMsg: []
    };
  },

  created() {
    // Init the form
    if (this.initData !== undefined && this.initData.date !== undefined) {
      this.form.date = this.initData.date;
      this.retriveIndexInstance(this.initData.date);
    } else {
      this.initForm();
    }
  },

  computed: {
    ...mapGetters(["selectedMeter"]),
    canAdd() {
      return (
        this.form.date !== null &&
        this.selectedMeter.meterStructure.dataKinds.find((kind) => {
          return (
            this.form.indexes[kind].value === null ||
            this.form.indexes[kind].value === ""
          );
        }) === undefined
      );
    },
  },
  methods: {
    ...mapActions(["closeModal", "createIndex"]),

    // needed for correct refresh ..
    updateFormValue(kind, value) {
      const form = { ...this.form };
      form.indexes[kind].value = value;
      this.form = form;
    },
    async retriveIndexInstance() {
      this.isLoading = true;
      const date = this.form.date;
      this.initForm(date);
      await this.$axios
        .get(`/api/my-meter/${this.selectedMeter.id}/indexes/`, {
          params: { date },
        })
        .then((resp) => {
          if (resp.data.count > 0) {
            const form = { ...this.form };
            resp.data.results.forEach((instance) => {
              form.indexes[instance.kind] = {
                id: instance.id,
                value: parseFloat(instance.value),
              };
            });
            this.form = form;
          }
        })
        .catch(() => {});
      this.isLoading = false;
    },
    async createIndexes() {
      this.isLoading = true;
      this.error = false;
      await this.createIndex(this.form)
        .then(() => this.retriveIndexInstance(this.form.date))
        .catch((e) => {
          this.error = true;
          this.errorMsg = []
          Object.keys(e.response.data).forEach(key => {
              if (Array.isArray(e.response.data[key])) {
                e.response.data[key].forEach(error => {
                  this.errorMsg.push(error)
                })
              }
            })
        });
      this.isLoading = false;
    },

    initForm(date = null) {
      this.form = {
        indexes: [],
        date,
      };
      this.selectedMeter.meterStructure.dataKinds.forEach((kind) => {
        this.form.indexes[kind] = {
          id: null,
          value: null,
        };
      });
    },
  },
};
</script>
