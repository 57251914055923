<template>
  <nav
    class="navbar-menu menu custom-box pt-3 pb-3 mb-5"
    :class="isLoggedNavbarOpen ? 'is-active' : ''"
    role="navigation"
    aria-label="main navigation"
    id="navbar"
  >
    <h2 class="menu-label pl-3 pb-3 pr-3 mb-0 is-hidden-touch">
      {{ $t("menu.myMonitoringAccount") }}
    </h2>
    <ul class="menu-list">
      <template v-for="item in items">
        <li
          class="navbar-item is-flex p-2"
          :class="{ 'is-active': isActiveMenu(item.url) }"
          :key="item.name"
        >
          <font-awesome-icon class="icon mr-2" :icon="item.icon" />
          <h3>
            <router-link :to="item.url">
              {{ $t(item.name) }}
            </router-link>
          </h3>
        </li>
      </template>
      <li
        class="navbar-item is-flex p-2 is-clickable"
        v-if="isLoggedIn"
        @click="logout"
      >
        <font-awesome-icon class="icon mr-2" :icon="signOutIcon" />
        <h3>
          <span>{{ $t("global.buttons.logout") }}</span>
        </h3>
      </li>
    </ul>
  </nav>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUser,
  faPencilAlt,
  faSearch,
  faSignOutAlt,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Menu",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      signOutIcon: faSignOutAlt,
      items: [
        {
          icon: faUser,
          name: "menu.dashboard",
          url: "/",
        },
        {
          icon: faPencilAlt,
          name: "menu.consumptionMeter",
          url: "/consumption-meter",
        },
        {
          icon: faChartLine,
          name: "menu.consumptionMonitor",
          url: "/comparative-monitor",
        },
        { icon: faSearch, name: "menu.helpContact", url: "/help-contact" },
      ],
    };
  },
  computed: {
    ...mapState(["isLoggedNavbarOpen"]),
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapActions(["logout"]),
    isActiveMenu(url) {
      return this.$route.path == url;
    },
  },
};
</script>
