import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import humps from 'humps'
import { modalInitializer } from "./modal-helper"
import router from '@/router/index'


const axiosConfig = {
  // Change to camel case
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(
      data,
      {
        process: function (key, convert, options) {
          return /^[A-Z_]+$/.test(key) ? key : convert(key, options);
        }
      }
    )
  ],
  // Change to snake case
  transformRequest: [
    data => { if (data instanceof FormData) { return data } else { return humps.decamelizeKeys(data) } },
    ...axios.defaults.transformRequest
  ],
};

// Axios adaptation for django rest framework
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
if (localStorage.getItem('authToken') !== null) {
  axios.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('authToken')}`;
}
// Allow us to request local django server
if (process.env.NODE_ENV == 'development') {
  axiosConfig['baseURL'] = 'http://127.0.0.1:8000/'
}

const axiosGlobalInstance = axios.create(axiosConfig)
// Add axios prototype to Vue global instance
Vue.prototype.$axios = axiosGlobalInstance

Vue.use(Vuex)

const collectionInitializer = function (overider = {}) {
  return {
    isLoaded: false,
    isLoading: true,
    data: { next: null, previous: null, results: [] },
    ...overider
  }
}

export default new Vuex.Store({
  state: {
    isLoggedNavbarOpen: false,
    errorLogin: "",
    errorRoute: "",
    token: localStorage.getItem('authToken') || null,
    meterStructuresCollection: collectionInitializer(),
    metersCollection: collectionInitializer(),
    indexCollection: collectionInitializer(),
    selectedMeter: null,
    modal: modalInitializer(),
    // consumption monitor
    consumptionCategories: ["ENERGY", "WASTE", "MOBILITY", "WATER"]
  },
  getters: {
    isLoggedIn: state => {
      return state.token !== null;
    },
    // meters
    isMetersLoading: state => {
      return state.metersCollection.isLoading;
    },
    metersData: state => {
      return state.metersCollection.data;
    },
    // meters Structures
    isMeterStructuresLoading: state => {
      return state.meterStructuresCollection.isLoading;
    },
    meterStructuresData: state => {
      return state.meterStructuresCollection.data;
    },
    selectedMeter: state => {
      return state.selectedMeter;
    },
    indexes: state => state.indexCollection.data,

    indexesResults: state => state.indexCollection.data.results,
    indexesLoaded: state => state.indexCollection.isLoaded,
    modal: state => state.modal,
    errorLogin: state => state.errorLogin,
    consumptionCategories: state => state.consumptionCategories
  },
  mutations: {
    TOGGLE_LOGGED_NAVBAR(state) {
      state.isLoggedNavbarOpen = !state.isLoggedNavbarOpen
    },
    SET_ERROR_LOGIN(state, error) {
      state.errorLogin = error;
    },

    SET_SELECTED_METER(state, selectedMeter) {
      state.selectedMeter = selectedMeter;
    },

    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('authToken', state.token);
    },
    START_LOAD_COLLECTION(state, collectionName) {
      state[collectionName] = collectionInitializer({
        isLoaded: false,
        isLoading: true
      })
    },
    LOAD_COLLECTION(state, { collectionName, data, isLoaded = true, isLoading = false }) {
      state[collectionName] = collectionInitializer({
        isLoaded,
        isLoading,
        data
      })
    },
    OPEN_MODAL(state, modalPayload) {
      state.modal = modalInitializer(modalPayload)
    },
    CLOSE_MODAL(state) {
      state.modal = modalInitializer()
    }
  },
  actions: {
    toggleLoggedNavbar({ commit }) {
      commit('TOGGLE_LOGGED_NAVBAR')
    },

    async getMeter({ commit }, id) {
      return axiosGlobalInstance
        .get(`/api/my-meter/${id}`).then((resp) => {
          commit('SET_SELECTED_METER', resp.data);
        })
    },
    async getMeterIndexes({ commit }, { id, url = null }) {
      commit('LOAD_COLLECTION', { collectionName: "indexCollection", data: { next: null, previous: null, results: [] }, isLoaded: false, isLoading: true });
      const finalUrl = (url) ? url : `/api/my-meter/${id}/indexes`;
      return axiosGlobalInstance
        .get(finalUrl).then((resp) => {
          commit('LOAD_COLLECTION', { collectionName: "indexCollection", data: resp.data });
        })
    },
    async getMeterCollection({ commit }, url) {
      commit('START_LOAD_COLLECTION', "metersCollection");
      return axiosGlobalInstance
        .get(url).then((resp) => commit('LOAD_COLLECTION', { collectionName: "metersCollection", data: resp.data }));
    },
    async getMeterStructuresCollection({ commit }) {
      commit('START_LOAD_COLLECTION', "meterStructuresCollection");
      return axiosGlobalInstance
        .get("/api/meter-struct/").then((resp) => commit('LOAD_COLLECTION', { collectionName: "meterStructuresCollection", data: resp.data }));
    },
    async createMeter({ dispatch }, payload) {
      return axiosGlobalInstance.post("/api/my-meter/", payload).then(() => dispatch("getMeterCollection", "/api/my-meter/"));
    },
    async deleteMeter({ dispatch, commit, state }) {
      return axiosGlobalInstance.delete(`/api/my-meter/${state.selectedMeter.id}`).then(() => {
        commit('SET_SELECTED_METER', null);
        dispatch("closeModal");
        dispatch("getMeterCollection", "/api/my-meter/");
      });
    },
    async createIndex({ dispatch, state }, { indexes, date }) {
      const promises = []
      // for each kind of data we have to create a new index
      Object.keys(indexes).forEach(kind => {
        const payload = {
          kind,
          date,
          id: indexes[kind].id,
          value: indexes[kind].value,
        }
        promises.push(
          (indexes[kind].id) ?
            axiosGlobalInstance.patch(
              `/api/my-meter/${state.selectedMeter.id}/indexes/${indexes[kind].id}/`, payload
            ) :
            axiosGlobalInstance.post(
              `/api/my-meter/${state.selectedMeter.id}/indexes/`, payload
            )
        )
      })
      return Promise.all(promises).then(() => dispatch("getMeterIndexes", { id: state.selectedMeter.id }))
    },

    async deleteIndex({ dispatch, state }, id) {
      return axiosGlobalInstance.delete(`/api/my-meter/${state.selectedMeter.id}/indexes/${id}`).then(() => {
        dispatch("getMeterIndexes", { id: state.selectedMeter.id })
      });
    },

    openDeleteModalMeter({ commit }, { modalPayload, meter }) {
      commit('SET_SELECTED_METER', meter);
      commit('OPEN_MODAL', modalPayload);
    },
    openModal({ commit }, modalPayload) {
      commit('OPEN_MODAL', modalPayload);
    },
    closeModal({ commit }) {
      commit('CLOSE_MODAL');
    },
    async login({ commit }, credentials) {
      // We send a request to get the token
      return axiosGlobalInstance
        .post("/api/login/", credentials)
        .then(response => {
          // we register the token in the store
          const token = response.data.token;
          commit('SET_TOKEN', token);
          // set auth header
          axiosGlobalInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
          router.push({
            name: 'Dashboard'
          })
        }).catch((error) => {
          if (error.response) {
            if (error.response.data) {
              const errorMsg = (error.response.data.detail === undefined) ? Object.keys(error.response.data).map(key => error.response.data[key]).join('-') : error.response.data.detail;
              commit('SET_ERROR_LOGIN', errorMsg);
            }
          }
          else {
            const errorMsg = "Server error. Try again please.";
            commit('SET_ERROR_LOGIN', errorMsg);
          }
        });
    },
    async logout({ commit, state }) {
      // We send a request to get the token
      return axiosGlobalInstance
        .get("/api/logout/", { token: state.token })
        .then(() => {
          commit('SET_TOKEN', null)
          localStorage.clear()
          // set auth header
          delete axiosGlobalInstance.defaults.headers.common['Authorization']
          router.push({
            name: 'RegisterLogin'
          })
        }).catch((error) => {
          console.log(error)
        });
    },
  }
})
