<template>
  <section class="register-login hero">
    <div v-if="errorVerify" class="notification is-danger">
      {{ errorVerify }}
    </div>
    <div v-if="isSuccess" class="notification has-text-centered is-success">
      <span v-if="isUserRegistered"> {{ $t("registerLogin.registered") }}</span>
      <span v-if="isUserVerified"> {{ $t("registerLogin.verified") }}</span>
      <span v-if="isPasswordReset">
        {{ $t("registerLogin.resetPassword") }}</span
      >
    </div>
    <div class="hero-body is-flex-centered">
      <div
        class="container columns is-justify-content-center has-text-centered"
      >
        <div class="column is-5">
          <div class="box">
            <div v-if="errorRegisterUser" class="notification is-danger">
              {{ errorRegisterUser }}
            </div>

            <form @submit.prevent="doRegister">
              <div class="columns mb-0">
                <div class="column">
                  <div class="field">
                    <label class="label has-text-left"
                      >{{
                        $t("dashboard.profile.form.fields.firstName.label")
                      }}*</label
                    >
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Your firstname"
                        :disabled="isLoading"
                        autofocus=""
                        required
                        autocomplete="on"
                        v-model="firstName"
                      />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label has-text-left"
                      >{{
                        $t("dashboard.profile.form.fields.lastName.label")
                      }}*</label
                    >
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Your lastname"
                        autofocus=""
                        required
                        autocomplete="on"
                        v-model="lastName"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns mb-0">
                <div class="column">
                  <div class="field">
                    <label class="label has-text-left"
                      >{{
                        $t("dashboard.profile.form.fields.email.label")
                      }}*</label
                    >
                    <div class="control">
                      <input
                        class="input"
                        type="email"
                        placeholder="Your email"
                        :disabled="isLoading"
                        autofocus=""
                        required
                        autocomplete="on"
                        v-model="emailRegister"
                      />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label has-text-left"
                      >{{
                        $t("dashboard.profile.form.fields.password.label")
                      }}*</label
                    >
                    <div class="control has-icons-right">
                      <input
                        @click="isPasswordHidden = !isPasswordHidden"
                        class="input"
                        :type="isPasswordHidden ? 'text' : 'password'"
                        placeholder="Your Password"
                        required
                        v-model="passwordRegister"
                      />

                      <span class="icon is-right">
                        <font-awesome-icon
                          v-if="isPasswordHidden"
                          class="mr-2"
                          :icon="seeIcon"
                        />
                        <font-awesome-icon
                          v-else
                          class="mr-2"
                          :icon="cantSeeIcon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <label class="label has-text-left"
                  >{{
                    $t("dashboard.profile.form.fields.country.label")
                  }}*</label
                >
                <div class="select is-fullwidth">
                  <select name="country" v-model="country" required>
                    <option
                      v-for="(country, countryIndex) in countries"
                      v-bind:key="countryIndex"
                      :value="country.code"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label has-text-left">{{
                      $t("dashboard.profile.form.fields.companyName.label")
                    }}</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Company name"
                        :disabled="isLoading"
                        autofocus=""
                        autocomplete="on"
                        v-model="companyName"
                      />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label has-text-left">{{
                      $t("dashboard.profile.form.fields.phone.label")
                    }}</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Your phone"
                        :disabled="isLoading"
                        autofocus=""
                        autocomplete="on"
                        v-model="phone"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="button is-block is-primary is-fullwidth"
                :disabled="isLoading"
                :class="{ 'is-loading': isRegisterLoading }"
              >
                Register <i class="fa fa-sign-in" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        </div>
        <div class="column is-4 login-form">
          <div class="box">
            <div v-if="errorLogin && !isLoading" class="notification is-danger">
              {{ errorLogin }}
            </div>
            <form @submit.prevent="doLogin">
              <div class="field">
                <label class="label has-text-left"
                  >{{ $t("dashboard.profile.form.fields.email.label") }}*</label
                >
                <div class="control">
                  <input
                    class="input"
                    type="email"
                    placeholder="Your email"
                    :disabled="isLoading"
                    autofocus=""
                    required
                    autocomplete="on"
                    v-model="email"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label has-text-left"
                  >{{
                    $t("dashboard.profile.form.fields.password.label")
                  }}*</label
                >
                <div class="control">
                  <input
                    class="input"
                    type="password"
                    placeholder="Your Password"
                    :disabled="isLoading"
                    required
                    v-model="password"
                  />
                </div>
              </div>
              <button
                class="button is-block is-info is-fullwidth"
                :disabled="isLoading"
                :class="{ 'is-loading': isLoginLoading }"
              >
                Login <i class="fa fa-sign-in" aria-hidden="true"></i>
              </button>
            </form>
          </div>
          <p class="has-text-grey">
            <span class="is-clickable" @click="isModalOpen = true"
              >{{ $t("registerLogin.forgotPassword") }}?</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="modal" :class="isModalOpen ? 'is-active' : ''">
      <div class="modal-background" @click="isModalOpen = false"></div>
      <div class="box p-6 modal-content">
        <div
          v-if="errorResetPassword"
          class="notification has-text-centered is-danger"
        >
          {{ $t(errorResetPassword) }}
        </div>
        <p class="mb-3 has-text-weight-bold">
          {{ $t("registerLogin.enterEmail") }}
        </p>
        <div class="field">
          <div class="control">
            <input
              class="input"
              type="email"
              placeholder="Your email"
              :disabled="isLoading"
              autofocus=""
              required
              autocomplete="on"
              v-model="emailRetrieve"
            />
          </div>
        </div>
        <div class="is-flex-centered">
          <button
            class="button is-block is-info"
            :class="{ 'is-loading': isLoading }"
            @click="resetPassword"
          >
            {{ $t("registerLogin.resetMyPassword") }}
          </button>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="isModalOpen = false"
      ></button>
    </div>
  </section>
</template>

<script>
import countries from "@/country.json";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "RegisterLogin",
  props: ["code"],
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      countries,
      /* Management section */
      isSuccess: false,
      isUserVerified: false,
      isPasswordHidden: true,
      isPasswordReset: false,
      isModalOpen: false,
      isUserRegistered: false,
      isLoading: false,
      isLoginLoading: false,
      isRegisterLoading: false,
      errorVerify: "",
      errorRegisterUser: "",
      errorResetPassword: "",
      /* Icons */
      seeIcon: faEye,
      cantSeeIcon: faEyeSlash,
      /* Form data */
      firstName: "",
      lastName: "",
      emailRegister: "",
      passwordRegister: "",
      email: "",
      password: "",
      zipCode: "",
      country: "",
      companyName: "",
      phone: "",
      emailRetrieve: "",
    };
  },
  /* If there is a code prop, the user wants to verify his email so we call the API */
  created() {
    if (this.isLoggedIn) {
      this.$router.push({
        name: "Dashboard",
      });
    }
    if (this.code) {
      this.isLoading = true;
      this.$axios
        .get(`/api/signup/verify/`, {
          params: {
            code: this.code,
          },
        })
        .then(() => {
          this.isSuccess = true;
          this.isUserVerified = true;
        })
        .catch(() => {
          this.errorVerify = "registerLogin.errorVerifyUser";
        });
      this.isLoading = false;
      this.code = "";
    }
  },
  computed: {
    ...mapGetters(["errorLogin", "isLoggedIn"]),
  },
  methods: {
    ...mapActions(["login", "register"]),
    async doLogin() {
      this.isLoginLoading = true;
      const credentials = {
        email: this.email,
        password: this.password,
      };
      this.isLoading = true;
      await this.login(credentials);
      this.isLoading = false;
      this.isLoginLoading = false;
    },
    async doRegister() {
      this.isLoading = true;
      this.isRegisterLoading = true;
      this.errorRegisterUser = "";
      const details = {
        email: this.emailRegister,
        password: this.passwordRegister,
        firstName: this.firstName,
        lastName: this.lastName,
        city: this.city,
        country: this.country,
        companyName: this.companyName,
        phone: this.phone,
      };
      await this.$axios
        .post("/api/signup/", details)
        .then(() => {
          this.isSuccess = true;
          this.isUserRegistered = true;
        })
        .catch((error) => {
          this.errorRegisterUser =
            error.response.data.detail === undefined
              ? error.response.statusText
              : error.response.data.detail;
        });
      this.isLoading = false;
      this.isRegisterLoading = false;
    },
    async resetPassword() {
      this.isLoading = true;
      this.errorResetPassword = "";
      await this.$axios
        .post("/api/password/reset/", { email: this.emailRetrieve })
        .then(() => {
          this.isSuccess = true;
          this.isModalOpen = false;
          this.isPasswordReset = true;
        })
        .catch(() => {
          this.errorResetPassword = "registerLogin.errorResetPassword";
        });
      this.isLoading = false;
    },
  },
};
</script>


