<template>
  <section class="reset-password hero">
    <div v-if="isLoading && !isCodeVerified" class="loader"></div>
    <div
      v-else-if="!isLoading && isPasswordChanged"
      class="notification has-text-centered is-success"
    >
      {{ $t("resetPassword.passwordChanged") }}
    </div>
    <div v-else>
      <div
        v-if="!isLoading && errorMsg"
        class="notification m-0 has-text-centered is-danger"
      >
        {{ $t(errorMsg) }}
      </div>
      <div class="hero-body is-flex-centered">
        <div class="container columns is-flex-centered">
          <form @submit.prevent="changePassword" class="column is-4">
            <div class="field">
              <div class="is-flex">
                <label class="label is-small has-text-left"
                  >{{ $t("resetPassword.newPassword") }}*</label
                ><span
                  @click="isPasswordHidden = !isPasswordHidden"
                  class="icon mr-3 pb-2"
                >
                  <font-awesome-icon v-if="isPasswordHidden" :icon="seeIcon" />
                  <font-awesome-icon v-else :icon="cantSeeIcon" />
                </span>
              </div>

              <div class="control has-icons-right">
                <input
                  class="input"
                  :type="isPasswordHidden ? 'password' : 'text'"
                  placeholder="Your Password"
                  required
                  v-model="newPassword1"
                />
              </div>
            </div>
            <div class="field">
              <div class="is-flex">
                <label class="label is-small has-text-left"
                  >{{ $t("resetPassword.confirmNewPassword") }}*</label
                ><span
                  @click="isPasswordHidden = !isPasswordHidden"
                  class="icon mr-3 pb-2"
                >
                  <font-awesome-icon v-if="isPasswordHidden" :icon="seeIcon" />
                  <font-awesome-icon v-else :icon="cantSeeIcon" />
                </span>
              </div>
              <div class="control has-icons-right">
                <input
                  class="input"
                  :type="isPasswordHidden ? 'password' : 'text'"
                  placeholder="Confirm your password"
                  required
                  v-model="newPassword2"
                />
              </div>
            </div>
            <button
              :disabled="isLoading"
              class="button is-block is-primary is-fullwidth"
              :class="{ 'is-loading': isLoading }"
            >
              {{ $t("resetPassword.changePassword") }}
              <i class="fa fa-sign-in" aria-hidden="true"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "ResetPassword",
  props: ["code"],
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      email: "",
      newPassword1: "",
      newPassword2: "",
      /* Management */
      isPasswordHidden: true,
      isLoading: true,
      isCodeVerified: false,
      isPasswordChanged: false,
      errorMsg: "",
      /* Icons */
      seeIcon: faEye,
      cantSeeIcon: faEyeSlash,
    };
  },
  /* First step before changing password : we verify the user email */
  created() {
    this.isLoading = true;
    if (this.code) {
      this.$axios
        .get(`/api/password/reset/verify/`, {
          params: {
            code: this.code,
          },
        })
        .then(() => {
          this.isCodeVerified = true;
        })
        .catch((error) => {
          this.errorMsg = error.response.data.detail;
        });
      this.isLoading = false;
    }
  },
  methods: {
    async changePassword() {
      this.isLoading = true;
      if (this.newPassword1 === this.newPassword2) {
        const details = {
          code: this.code,
          password: this.newPassword1,
        };
        await this.$axios
          .post("/api/password/reset/verified/", details)
          .then(() => {
            this.isPasswordChanged = true;
          })
          .catch((error) => {
            this.errorMsg = Object.keys(error.response.data)
              .map((key) => key.toUpperCase() + ": " + error.response.data[key])
              .join("-");
          });
      } else {
        this.errorMsg = "resetPassword.identicalPasswords";
      }
      this.isLoading = false;
    },
  },
};
</script>
