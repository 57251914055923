<template>
  <div class="modal" :class="{ 'is-active': modal.isOpen }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modal.title }}</p>
        <font-awesome-icon
          class="button-icon"
          :icon="closeIcon"
          @click="closeModal"
        />
      </header>
      <component
        v-if="modal.componentName !== ''"
        v-bind:is="modal.componentName"
        :initData="modal.initData"
      ></component>
    </div>
    <button class="modal-close is-large" aria-label="close">
      <font-awesome-icon
        class="button-icon"
        :icon="closeIcon"
        @click="closeModal"
      />
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";

// possible component in the modal
import DeleteMeter from "@/components/Meter/DeleteMeter";
import CreateUpdateIndex from "@/components/Index/CreateUpdateIndex";

export default {
  name: "Modal",
  props: {},
  components: {
    FontAwesomeIcon,
    DeleteMeter,
    CreateUpdateIndex,
  },
  data() {
    return {
      closeIcon: faTimes,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["modal"]),
  },
  methods: {
    ...mapActions(["closeModal"]),
  },
};
</script>