<template>
  <div class="add-new-meter is-fullwidth">
    <div v-if="isMeterStructuresLoading">
      <div class="loader"></div>
    </div>
    <div v-else>
      <h2 class="menu-label p-3 is-fullwidth">
        {{ $t("consumptionMeter.addNewMeter") }}
      </h2>
      <form @submit.prevent="handleForm" class="p-3 pr-5">
        <label for="meter-type-select">
          {{ $t("consumptionMeter.name") }}:
        </label>
        <input
          type="text"
          class="input"
          required
          maxlength="120"
          v-model="name"
        />

        <label for="meter-type-select"
          >{{ $t("consumptionMeter.meterType") }}:</label
        >
        <div class="select is-fullwidth mb-5">
          <select v-model="selectedMeterStructure">
            <option
              v-for="meterStructure in meterStructures"
              :value="meterStructure.id"
              :key="meterStructure.id"
              required
            >
              {{ $t(meterStructure.kind) }}
            </option>
          </select>
        </div>
        <div class="buttons is-justify-content-flex-end">
          <div class="button is-light is-rounded">
            <span>{{ $t("global.buttons.reset") }}</span>
          </div>
          <button
            :disabled="isLoading || !isFormValid"
            type="submit"
            class="button is-primary is-rounded"
          >
            <span>{{ $t("global.buttons.save") }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddNewMeter",
  data() {
    return {
      name: "",
      selectedMeterStructure: null,
      isLoading: false,
    };
  },
  created() {
    this.getMeterStructuresCollection();
  },
  computed: {
    ...mapGetters(["meterStructuresData"]),
    ...mapGetters(["isMeterStructuresLoading"]),
    isFormValid() {
      return this.selectedMeterStructure !== null && this.name.length > 0;
    },
    meterStructures() {
      return this.meterStructuresData.results;
    },
  },
  methods: {
    ...mapActions(["getMeterStructuresCollection", "createMeter"]),
    async handleForm() {
      this.isLoading = true;
      await this.createMeter({
        name: this.name,
        meterStructure: this.selectedMeterStructure,
      });
      this.name = "";
      this.selectedMeterStructure = null;
      this.isLoading = false;
    },
  },
};
</script>