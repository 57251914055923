<template>
  <div class="change-password custom-box mr-3">
    <h2 class="menu-label p-3 mb-3">
      {{ $t("dashboard.changePassword.title") }}
    </h2>
    <div>
      <div
        v-if="!isLoading && errorMsg"
        class="notification mx-3 has-text-centered is-danger"
      >
        {{ $t(errorMsg) }}
      </div>
      <div
        v-if="!isLoading && successMsg"
        class="notification mx-3 has-text-centered is-success"
      >
        {{ $t(successMsg) }}
      </div>
      <form @submit.prevent="changePassword" class="column is-6">
        <div class="field">
          <div class="is-flex">
            <label class="label has-text-left"
              >{{ $t("resetPassword.newPassword") }}*</label
            ><span @click="isPasswordHidden = !isPasswordHidden" class="icon">
              <font-awesome-icon v-if="isPasswordHidden" :icon="seeIcon" />
              <font-awesome-icon v-else :icon="cantSeeIcon" />
            </span>
          </div>

          <div class="control has-icons-right">
            <input
              class="input"
              :type="isPasswordHidden ? 'password' : 'text'"
              placeholder="Your Password"
              required
              v-model="newPassword1"
            />
          </div>
        </div>
        <div class="field my-5">
          <div class="is-flex">
            <label class="label has-text-left"
              >{{ $t("resetPassword.confirmNewPassword") }}*</label
            ><span @click="isPasswordHidden = !isPasswordHidden" class="icon">
              <font-awesome-icon v-if="isPasswordHidden" :icon="seeIcon" />
              <font-awesome-icon v-else :icon="cantSeeIcon" />
            </span>
          </div>
          <div class="control has-icons-right">
            <input
              class="input"
              :type="isPasswordHidden ? 'password' : 'text'"
              placeholder="Confirm your password"
              required
              v-model="newPassword2"
            />
          </div>
        </div>
        <div class="buttons mb-2 p-2 pt-0 is-flex is-justify-content-flex-end">
          <router-link
            :to="{ name: 'Dashboard' }"
            class="button is-light is-rounded"
          >
            {{ $t("global.buttons.back") }}
          </router-link>
          <button
            class="button is-rounded is-primary"
            type="submit"
            :class="{ 'is-loading': isLoading }"
          >
            {{ $t("resetPassword.changePassword") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "ChangePassword",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      newPassword1: "",
      newPassword2: "",
      /* Management */
      isPasswordHidden: true,
      isLoading: false,
      errorMsg: "",
      successMsg: "",
      /* Icons */
      seeIcon: faEye,
      cantSeeIcon: faEyeSlash,
    };
  },
  methods: {
    async changePassword() {
      this.errorMsg = "";
      this.successMsg = "";
      this.isLoading = true;
      if (this.newPassword1 === this.newPassword2) {
        await this.$axios
          .post("/api/password/change/", { password: this.newPassword1 })
          .then((response) => {
            this.successMsg = Object.keys(response.data)
              .map((key) => response.data[key])
              .join("-");
          })
          .catch((error) => {
            this.errorMsg = Object.keys(error.response.data)
              .map((key) => key.toUpperCase() + ": " + error.response.data[key])
              .join("-");
          });
      } else {
        this.errorMsg = "resetPassword.identicalPasswords";
      }
      this.isLoading = false;
    },
  },
};
</script>