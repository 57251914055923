<template>
  <div class="hero is-flex-column-centered is-fullheight">
    <div class="hero-body is-flex-column-centered">
      <h2 class="m-3 is-size-2">{{ $t("pageNotFound.error") }}</h2>
      <p>{{ $t("pageNotFound.sorry") }}</p>
      <button class="m-3 button is-primary" @click="$router.go(-1)">
        {{ $t("global.buttons.back") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
