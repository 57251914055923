<template>
  <div class="custom-box">
    <div v-if="isLoading" class="loader"></div>
    <div v-else>
      <h2>{{ $t("monitor.evolution.title") }}</h2>
      <line-chart
        v-if="!isLoading && selectedMeter !== null"
        :id="'evolution' + getIntColor()"
        :chart-data="chartData"
        :options="options"
      ></line-chart>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LineChart from "@/components/Charts/LineChart";
import moment from "moment";

export default {
  name: "MeterEvolution",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LineChart,
  },

  data() {
    return {};
  },
  methods: {
    getIntColor() {
      return Math.floor(Math.random() * 255);
    },
  },
  computed: {
    ...mapGetters(["selectedMeter", "indexesResults"]),
    chartData() {
      /* If we want all dates */
      let minDate = "9999-99-99";
      let maxDate = "0000-00-00";
      const labels = [];
      const filtredDatas = {};
      // init
      this.selectedMeter.meterStructure.dataKinds.forEach((kind) => {
        filtredDatas[kind] = [];
      });

      // filter results
      this.indexesResults.forEach((index) => {
        if (minDate > index.date) {
          minDate = index.date;
        }
        if (maxDate < index.date) {
          maxDate = index.date;
        }
        const copyIndex = { ...index };
        if (filtredDatas[index.kind].length > 0) {
          copyIndex["consumation"] = parseFloat(copyIndex.value) - parseFloat(filtredDatas[index.kind][0].value);
        } else {
          copyIndex["consumation"] = 0;
        }
        filtredDatas[index.kind].push(copyIndex);
      });

      for (
        let m = moment(minDate);
        m.diff(maxDate, "days") <= 0;
        m.add(1, "days")
      ) {
        labels.push(m.format("YYYY-MM-DD"));
      }

      const datasets = this.selectedMeter.meterStructure.dataKinds.map(
        (kind) => {
          return {
            label: `${this.selectedMeter.meterStructure.unit} cumulated consumption`,
            borderColor: '#aa1608',
            data: filtredDatas[kind].map((index) => {
              return {
                y: index.consumation,
                x: index.date,
              };
            }),
          };
        }
      );

      return {
        datasets,
        labels,
        fill: false,
      };
    },
    options() {
      return {
        plugins: {legend: {onClick: null}},
        scales: {
          x: {
            title: {display: true, text: 'days'}
          },
          y: {
            title: {display: true, text: this.selectedMeter.meterStructure.unit},
            beginAtZero: true,
          },
        },
        responsive: true,
      };
    },
  },
};
</script>
