<template>
  <div class="is-flex is-justify-content-space-between">
    <h2 class="menu-label">{{ $t("monitor.index") }}</h2>
    <router-link
      to="/consumption-monitor/add"
      class="button is-small is-primary is-rounded"
    >
      <span class="icon is-small">
        <font-awesome-icon class="button-icon" :icon="plusIcon" />
      </span>
      <span class="has-text-weight-semibold">{{ $t("monitor.newIndex") }}</span>
    </router-link>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "MeterIndexList",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      plusIcon: faPlus,
    };
  },
};
</script>