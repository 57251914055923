<template>
  <div>
    <section class="modal-card-body">
      {{ $t("consumptionMeter.delete.sentence", { name: selectedMeter.name }) }}
    </section>
    <footer class="modal-card-foot">
      <div class="button" @click="closeModal">
        {{ $t("global.buttons.back") }}
      </div>
      <div class="button is-danger" @click="deleteMeter">
        {{ $t("global.buttons.delete") }}
      </div>
    </footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeleteMeter",
  computed: {
    ...mapGetters(["selectedMeter"]),
  },
  methods: {
    ...mapActions(["closeModal", "deleteMeter"]),
  },
};
</script>