<template>
  <div class="consumption-meter is-fullheight columns">
    <MyMeters class="custom-box column p-0 is-flex-grow-3" />
    <AddNewMeter class="custom-box column p-0 my-3" />
  </div>
</template>

<script>
import MyMeters from "@/components/Meter/MyMeters";
import AddNewMeter from "@/components/Meter/AddNewMeter";

export default {
  name: "ConsumptionMeter",
  components: { MyMeters, AddNewMeter },
};
</script>