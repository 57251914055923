<template>
  <div class="dashboard is-fullheight is-fullwidth columns">
    <div class="custom-box column my-3 ml-3 is-paddingless is-fullwidth">
      <Profile />
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile/Profile";
export default {
  name: "Dashboard",
  components: {
    Profile,
  },
};
</script>