<template>
  <div class="consumption-monitor is-fullwidth is-fullheight columns">
    <div
      class="column is-flex-grow-3 is-flex-1 is-fullwidth custom-box m-3 p-2"
    >
      <MeterProperties
        :is-loading="isLoadingMeter"
        class="mb-3 lined-container p-3"
      />
      <MeterEvolution
        :is-loading="!indexesLoaded || isLoadingMeter"
        class="lined-container p-3"
      />
    </div>
    <div class="custom-box column my-3">
      <div class="lined-container p-3">
        <h2>
          {{ $t("monitor.index.title") }}
          <button
            class="button is-rounded is-blue is-pulled-right"
            @click="openIndexCreationModal"
          >
            <font-awesome-icon class="mr-2" :icon="plusIcon" />
            {{ $t("monitor.addNewIndex") }}
          </button>
        </h2>

        <index-list :is-loading="!indexesLoaded && !isLoadingMeter" />
      </div>
    </div>
  </div>
</template>

<script>
import MeterProperties from "@/components/Meter/MeterProperties";
import MeterEvolution from "@/components/Meter/MeterEvolution";
import { mapActions, mapGetters } from "vuex";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import IndexList from "@/components/Index/IndexList.vue";
export default {
  name: "monitor",
  components: { MeterProperties, MeterEvolution, FontAwesomeIcon, IndexList },
  props: {
    id: {
      // pass by router
      required: true,
    },
  },

  data() {
    return {
      plusIcon: faPlus,
      isLoadingMeter: true,
    };
  },

  created() {
    this.getMeter(this.id).then(() => {
      this.isLoadingMeter = false;
    });
    this.getMeterIndexes({ id: this.id });
  },

  computed: {
    ...mapGetters(["indexesLoaded"]),
  },

  //Retrieve data
  methods: {
    ...mapActions(["getMeter", "getMeterIndexes", "openModal"]),
    openIndexCreationModal() {
      this.openModal({
        title: this.$t("monitor.addNewIndex"),
        componentName: "CreateUpdateIndex",
        isOpen: true,
      });
    },
  },
};
</script>