<template>
  <div>
    <div v-if="isLoading" class="loader" />
    <div v-else>
      <table v-if="indexes.count !== 0" class="table is-fullwidth mt-2">
        <thead class="is-flex is-flex-1">
          <th class="is-flex-2">
            {{ $t("monitor.index.list.headers.date") }}
          </th>
          <th class="is-flex-2">
            {{ $t("monitor.index.list.headers.index") }}
          </th>
          <th class="is-flex-1"></th>
        </thead>
        <tbody
          class="is-flex is-flex-1 is-flex-direction-column is-overflow-y"
          style="max-height: 400px"
        >
          <tr v-if="deleteLoading || reloadLoading">
            <td></td>
            <td class="loader is-flex-1" />
            <td></td>
          </tr>
          <tr
            v-else
            v-for="index in indexes.results"
            class="is-flex"
            :key="index.id"
          >
            <td class="is-flex-2">
              {{ index.date }}
            </td>
            <td class="is-flex-2 is-flex">
              {{ index.value }} {{ selectedMeter.meterStructure.unit }}
            </td>
            <td class="is-flex-1">
              <font-awesome-icon
                class="mr-2"
                :icon="editIcon"
                @click="editIndex(index)"
              />
              <font-awesome-icon
                class="mr-2"
                :icon="deleteIcon"
                @click="deleteIndexFromList(index.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="buttons is-fullwidth">
        <button
          v-if="indexes.previous"
          class="button is-rounded is-pulled-left"
          @click="getResultPagination(indexes.previous)"
        >
          {{ $t("global.buttons.previous") }}
        </button>
        <button
          v-if="indexes.next"
          class="button is-rounded is-pulled-right"
          @click="getResultPagination(indexes.next)"
        >
          {{ $t("global.buttons.next") }}
        </button>
      </div>
      <p v-if="indexes.count === 0">
        {{ $t("monitor.index.list.empty") }}
      </p>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "IndexList",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      deleteIcon: faTrash,
      editIcon: faPen,
      deleteLoading: false,
      reloadLoading: false,
    };
  },
  props: {
    isLoading: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["indexes"]),
    ...mapGetters(["selectedMeter"]),
  },
  methods: {
    ...mapActions(["deleteIndex", "getMeterIndexes", "openModal"]),
    async getResultPagination(url) {
      this.reloadLoading = true;
      await this.getMeterIndexes({ id: this.selectedMeter.id, url });
      this.reloadLoading = false;
    },

    editIndex(index) {
      this.openModal({
        title: this.$t("monitor.editIndex"),
        componentName: "CreateUpdateIndex",
        isOpen: true,
        initData: {
          date: index.date,
        },
      });
    },
    async deleteIndexFromList(id) {
      this.deleteLoading = true;
      await this.deleteIndex(id);
      this.deleteLoading = false;
    },
  },
};
</script>