<template>
  <div :id="`chart-container-${id}`" class="is-relative">
    <canvas :id="id" />
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: "LineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "line",
    },
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  watch: {
    chartData() {
      if (this.chart === null) return;
      this.chart.destroy();
      const container = document.getElementById(`chart-container-${this.id}`);
      const newChartDiv = document.createElement("div");
      newChartDiv.setAttribute("id", this.id);
      container.appendChild(newChartDiv);
      this.createChart();
    },
  },

  mounted() {
    this.createChart();
  },
  beforeDestroy() {
    this.chart.destroy();
  },

  methods: {
    createChart() {
      const el = document.getElementById(this.id);
      const ctx = el.getContext("2d");
      this.chart = new Chart(ctx, {
        type: this.type,
        data: this.chartData,
        options: this.options,
      });
    },
  },
};
</script>
