<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>© Copyright - ECHO-Tourism | Website development : <a href="https://hashbang.fr">HashBang</a></p>
      <p>This project has been funded with the support of the European Commission. This publication is the sole responsibility of the author and the Commission is not responsible for any use that may be made of the information contained therein.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
