import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Dashboard from '@/views/Dashboard.vue'
import ConsumptionMeter from '@/views/ConsumptionMeter.vue'
import ConsumptionMonitor from '@/views/ConsumptionMonitor.vue'
import Monitor from '@/views/Monitor.vue'
import HelpContact from '@/views/HelpContact.vue'
import RegisterLogin from '@/views/RegisterLogin.vue'

import MeterIndexList from '@/components/MeterIndexList.vue'
import PageNotFound from '@/components/PageNotFound.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import ChangePassword from '@/components/ChangePassword.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'RegisterLogin',
    component: RegisterLogin
  },
  {
    path: '/verify/:code',
    name: 'Verify',
    component: RegisterLogin, props: true
  },
  {
    path: '/password/reset/verify/:code',
    name: 'ResetPassword',
    component: ResetPassword, props: true
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/consumption-meter',
    name: 'ConsumptionMeter',
    component: ConsumptionMeter,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/comparative-monitor/',
    component: ConsumptionMonitor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/consumption-monitor/:id',
    name: 'Monitor',
    component: Monitor,
    props: true,
    children: [
      {
        path: 'index-list',
        component: MeterIndexList
      },
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help-contact',
    name: 'HelpContact',
    component: HelpContact, meta: {
      requiresAuth: true
    }
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ name: 'RegisterLogin' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
