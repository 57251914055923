<template>
  <div class="meter-properties">
    <div v-if="isLoading" class="loader"></div>
    <div v-else>
      <h2>{{ $t("monitor.meterProperties.title") }}
      </h2>
      <p class="button is-rounded has-background-warning is-pulled-right">
        <router-link :to="'/consumption-meter'">
          {{ $t("monitor.endOfInput") }}
        </router-link>
      </p>
      <p class="mb-2">
        <span class="meter-property-title">
          {{ $t("monitor.meterProperties.name") }}:
        </span>
        <span class="meter-property">
          {{ meter.name }}
        </span>
      </p>
      <p class="mb-2">
        <span class="meter-property-title">
          {{ $t("monitor.meterProperties.inputMode") }}:
        </span>
        <span class="meter-property">
          {{ meter.meterStructure.kind }}
        </span>
      </p>
      <p class="mb-2">
        <span class="meter-property-title">
          {{ $t("monitor.meterProperties.inputHelp") }}:
        </span>
        <span class="meter-property">
          {{ meter.meterStructure.helpText }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MeterProperties",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ meter: "selectedMeter" }),
  },
};
</script>
