<template>
  <div class="help-contact is-fullwidth is-fullheight columns">
    <div class="custom-box column p-0 my-3 ml-3 is-fullwidth">
      <h2 class="menu-label p-3">{{ $t("menu.helpContact") }}</h2>
      <div class="is-flex is-align-items-center mb-4">
        <span class="px-3 has-text-weight-bold">{{
          $t("help-contact.needHelp")
        }}</span>
        <button class="button is-link">
          <a
            class="has-text-white"
            href="./files/guide_utilisateur_fr.pdf"
            target="_blank"
            download
          >
            {{ $t("help-contact.downloadUserGuide") }}</a
          >
        </button>
      </div>

      <form
        v-if="!isSent"
        class="help-form pr-3 pl-3 pt-3 pb-6"
        @submit.prevent="sendForm"
      >
        <div class="columns mb-0">
          <div class="column">
            <div class="field">
              <label class="label">{{ $t("help-contact.firstname") }}*</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  :placeholder="$t('help-contact.firstname')"
                  v-model="firstname"
                  required
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">{{ $t("help-contact.lastname") }}*</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  :placeholder="$t('help-contact.lastname')"
                  v-model="lastname"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="columns mb-0">
          <div class="column">
            <div class="field">
              <label class="label">{{ $t("help-contact.email") }}*</label>
              <div class="control">
                <input
                  class="input"
                  type="email"
                  :placeholder="$t('help-contact.email')"
                  v-model="email"
                  required
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">{{ $t("help-contact.phone") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  :placeholder="$t('help-contact.phone')"
                  v-model="phone"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">{{ $t("help-contact.subject") }}*</label>
          <div class="control">
            <input
              class="input"
              type="text"
              :placeholder="$t('help-contact.subject')"
              v-model="subject"
              required
            />
          </div>
        </div>

        <div class="field">
          <label class="label">{{ $t("help-contact.message") }}*</label>
          <div class="control">
            <textarea
              rows="7"
              class="textarea"
              :placeholder="$t('help-contact.message')"
              v-model="message"
              required
            ></textarea>
          </div>
        </div>
        <div class="field has-text-right">
          <button
            class="button is-rounded is-primary"
            :class="{ 'is-loading': isLoading }"
          >
            {{ $t("global.buttons.submit") }}
          </button>
        </div>
      </form>
      <div v-else-if="isSent && !isError" class="notification is-success">
        {{ $t("help-contact.messageSent") }}
      </div>
      <div v-if="isError" class="notification is-danger">
        {{ $t("help-contact.messageNotSent") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpContact",
  data() {
    return {
      isError: false,
      isLoading: false,
      isSent: false,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };
  },
  methods: {
    sendForm() {
      this.isLoading = true;
      // We send a request to get the token
      this.$axios
        .post("/api/contact/", {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          subject: this.subject,
          message: this.message,
        })
        .then(() => {
          this.isSent = true;
        })
        .catch(() => {
          this.isError = true;
        });
      this.isLoading = false;
    },
  },
};
</script>
