<template>
  <div class="my-meters m-3 is-fullwidth pb-6">
    <h2 class="menu-label p-3 is-fullwidth">
      {{ $t("consumptionMeter.myMeters") }}
    </h2>
    <div
      class="
        meters-container
        columns
        is-multiline
        p-3
        margin-bottom-0
        is-fullwidth
      "
    >
      <div v-if="isMetersLoading" class="column is-12 p-4">
        <div class="loader"></div>
      </div>
      <div v-else-if="metersData.length == 0" class="container">
        {{ $t("consumptionMeter.emptyList") }}
      </div>
      <div
        v-else
        class="column is-6 p-4"
        v-for="meter in meters"
        :key="meter.id"
      >
        <div class="custom-box meter-card">
          <div class="header px-3 has-background-info is-flex-centered">
            <div class="text">{{ $t(meter.meterStructure.kind) }}</div>
            <div class="is-flex">
              <div
                class="button box is-small is-danger is-rounded ml-2"
                @click="() => openDeleteModal(meter)"
              >
                <span class="icon">
                  <font-awesome-icon class="button-icon" :icon="deleteIcon" />
                </span>
              </div>
            </div>
          </div>
          <div class="is-flex-column-centered my-2">
            <h3 class="is-size-2">{{ meter.name }}</h3>
          </div>
          <div class="daily-averages is-flex my-2">
            <div class="recent-average is-flex-column-centered">
              <span class="has-text-weight-semibold">
                {{ meter.extraData.consumptionPrevPrevMonthAverage || "--" }}
                {{ meter.meterStructure.unit }}
                {{ $t("consumptionMeter.prevPrevMonthAverage") }}
              </span>
            </div>
            <div class="recent-average is-flex-column-centered">
              <span class="has-text-weight-semibold">
                {{ meter.extraData.consumptionPrevMonthAverage || "--" }}
                {{ meter.meterStructure.unit }}
                {{ $t("consumptionMeter.prevMonthAverage") }}
              </span>
            </div>
          </div>
          <div class="graph-container is-relative">
            <line-chart
              :id="`meter-graph-${meter.id}`"
              :chartData="{
                datasets: [{
                  type: 'bar',
                  label: `${meter.meterStructure.unit} average comsumption / day`,
                  data: meter.extraData.monthConsumption,
                  backgroundColor: '#292961',
                }],
                labels: meter.extraData.monthConsumption.map((el, index) =>
                  $t(`${index + 1}`)
                ),

              }"
              :options="{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {legend: {onClick: null}},
                scales: {
                  x: {title: {display: true, text: 'days'}},
                  y: {title: {display: true, text: `${meter.meterStructure.unit}`}},
                }
              }"
            />
          </div>
          <div class="see-details mt-2 is-flex">
            <router-link
              class="see-details"
              :to="{ name: 'Monitor', params: { id: meter.id } }"
            >
              <span class="has-text-weight-semibold"
                >{{ $t("consumptionMeter.addData") }} >></span
              >
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <nav
      class="pagination is-small"
      role="navigation"
      aria-label="pagination"
      v-if="metersData.length !== 0"
      :class="{
        'is-justify-content-flex-end': metersData.previous === null,
      }"
    >
      <div
        class="button is-small is-rounded is-light"
        v-if="metersData.previous"
        @click="goPreviousPage"
      >
        {{ $t("global.buttons.previous") }}
      </div>
      <div
        class="button is-small is-rounded is-light"
        v-if="metersData.next"
        @click="goNextPage"
      >
        {{ $t("global.buttons.next") }}
      </div>
    </nav>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
import LineChart from "@/components/Charts/LineChart.vue";
export default {
  name: "MyMeters",
  components: {
    FontAwesomeIcon,
    LineChart,
  },
  data() {
    return {
      plusIcon: faPlus,
      deleteIcon: faTrash,
    };
  },
  created() {
    this.getMeterCollection("api/my-meter/");
  },
  methods: {
    ...mapActions([
      "getMeterCollection",
      "deleteMeter",
      "openDeleteModalMeter",
    ]),
    openDeleteModal(meter) {
      this.openDeleteModalMeter({
        modalPayload: {
          title: this.$t("consumptionMeter.delete.title"),
          componentName: "deleteMeter",
          isOpen: true,
        },
        meter,
      });
    },
    goNextPage() {
      this.getMeterCollection(this.metersData.next);
    },
    goPreviousPage() {
      this.getMeterCollection(this.metersData.previous);
    },
    customOnClickLegend() {
      window.console.log('coucou')
    }
  },
  computed: {
    ...mapGetters(["metersData", "isMetersLoading"]),
    meters() {
      return this.metersData.results;
    },
  },
};
</script>
