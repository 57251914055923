<template>
    <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang">
          {{ lang }}
      </option>
    </select>
</template>

<script>
export default {
  name: 'SwitchLanguage',
  data() {
    return { langs: ['en', 'fr', 'hr', 'it', 'gr'] }
  }
}
</script>
