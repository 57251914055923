<template>
  <div id="app">
    <div v-if="!isLoggedIn">
      <Navbar />
      <router-view />
    </div>
    <div v-else>
      <div class="top-bar columns is-marginless">
        <div class="top-bar-inner pr-0 column is-flex-touch">
          <a href="/">
            <img src="@/assets/logo.png" class="pt-3 pl-3" id="logo" />
          </a>
          <a
            role="button"
            class="navbar-burger"
            :class="isLoggedNavbarOpen ? 'is-active' : ''"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar"
            @click="toggleLoggedNavbar"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <SwitchLanguage />

        <h1 class="primary-blue column is-size-1 has-text-right is-hidden-touch">
          {{ $t("global.title") }}
        </h1>
      </div>
      <div class="main-container mt-3 is-flex-desktop">
        <Menu />
        <main class="main is-flex-grow-3"><router-view /></main>
      </div>
    </div>
    <Footer />
    <modal />
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal/Modal";
import SwitchLanguage from '@/components/SwitchLanguage.vue'
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Home",
  components: {
    Menu,
    Footer,
    Navbar,
    Modal,
    SwitchLanguage,
  },
  computed: {
    ...mapState(["isLoggedNavbarOpen"]),
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapActions(["toggleLoggedNavbar"]),
  },
};
</script>

<style lang="scss">
@charset "utf-8";
@import "scss/app.scss";
</style>
