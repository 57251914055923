<template>
  <div class="profile">
    <h2 class="menu-label p-3 mb-3">{{ $t("dashboard.profile.title") }}</h2>
    <div v-if="isUpdated" class="mx-3 notification is-success">
      {{ $t("dashboard.profile.updated") }}
    </div>
    <form class="p-3" @submit.prevent="updateProfile">
      <div class="columns is-multiline">
        <div class="column">
          <div class="field">
            <label for="first-name" class="label">
              {{ $t("dashboard.profile.form.fields.firstName.label") }}*
            </label>
            <p class="control">
              <input
                name="first-name"
                v-model="form.firstName"
                class="input"
                type="text"
                :placeholder="
                  $t('dashboard.profile.form.fields.firstName.placeholder')
                "
                required
              />
            </p>
          </div>

          <div class="field">
            <label for="last-name" class="label">
              {{ $t("dashboard.profile.form.fields.lastName.label") }}*
            </label>
            <p class="control">
              <input
                name="last-name"
                v-model="form.lastName"
                class="input"
                type="text"
                :placeholder="
                  $t('dashboard.profile.form.fields.lastName.placeholder')
                "
                required
              />
            </p>
          </div>

          <div class="field">
            <label for="company-name" class="label">
              {{ $t("dashboard.profile.form.fields.companyName.label") }}
            </label>
            <p class="control">
              <input
                name="company-name"
                v-model="form.companyName"
                class="input"
                type="text"
                :placeholder="
                  $t('dashboard.profile.form.fields.companyName.placeholder')
                "
              />
            </p>
          </div>
        </div>

        <!-- SECOND COLUMN -->
        <div class="column">
          <div class="field">
            <label for="country" class="label">
              {{ $t("dashboard.profile.form.fields.country.label") }}*
            </label>
            <div class="select">
              <select
                class="is-fullwidth"
                name="country"
                v-model="form.country"
                required
              >
                <option
                  v-for="(country, countryIndex) in countries"
                  v-bind:key="countryIndex"
                  :value="country.code"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="field">
            <label for="email" class="label">
              {{ $t("dashboard.profile.form.fields.email.label") }}*
            </label>
            <p class="control">
              <input
                name="email"
                v-model="form.email"
                class="input"
                type="email"
                :placeholder="
                  $t('dashboard.profile.form.fields.email.placeholder')
                "
                required
              />
            </p>
          </div>

          <div class="field">
            <label for="phone" class="label">
              {{ $t("dashboard.profile.form.fields.phone.label") }}
            </label>
            <p class="control">
              <input
                name="phone"
                v-model="form.phone"
                class="input"
                type="tel"
                :placeholder="$t('dashboard.profile.form.fields.phone.placeholder')"
              />
            </p>
          </div>
        </div>

        <!-- THIRD COLUMN -->
        <div class="column">
          <div class="columns mt-0">
            <div class="column is-paddingless">
              <div class="field pl-3">
                <label for="air-conditioner" class="label">
                  {{
                    $t("dashboard.profile.form.fields.airConditioning.label")
                  }}*
                </label>
                <p class="is-flex is-align-items-center input-height">
                  <input
                    id="air-conditioning-true"
                    v-model="form.airConditioning"
                    class="radio mr-2"
                    :value="true"
                    type="radio"
                    :checked="form.airConditioning"
                  />
                  <label for="air-conditioning-true" class="label mb-0">
                    {{
                      $t(
                        "dashboard.profile.form.fields.airConditioningTrue.label"
                      )
                    }}
                  </label>

                  <input
                    id="air-conditioning-false"
                    v-model="form.airConditioning"
                    class="radio ml-3 mr-2"
                    :value="false"
                    type="radio"
                    :checked="!form.airConditioning"
                  />
                  <label for="air-conditioning-false" class="label">
                    {{
                      $t(
                        "dashboard.profile.form.fields.airConditioningFalse.label"
                      )
                    }}
                  </label>
                </p>
              </div>
            </div>

            <div class="column is-paddingless">
              <div class="field mb-0 pl-3">
                <label for="electrical-heater" class="label">
                  {{
                    $t("dashboard.profile.form.fields.electricalHeater.label")
                  }}*
                </label>
                <p class="is-flex is-align-items-center input-height">
                  <input
                    id="electrical-heater-true"
                    v-model="form.electricalHeater"
                    class="radio mr-2"
                    :value="true"
                    type="radio"
                    :checked="form.electricalHeater"
                  />
                  <label for="electrical-heater-true" class="label mb-0">
                    {{
                      $t(
                        "dashboard.profile.form.fields.electricalHeaterTrue.label"
                      )
                    }}
                  </label>

                  <input
                    id="electrical-heater-false"
                    v-model="form.electricalHeater"
                    class="radio ml-3 mr-2"
                    :value="false"
                    type="radio"
                    :checked="!form.electricalHeater"
                  />
                  <label for="electrical-heater-false" class="label">
                    {{
                      $t(
                        "dashboard.profile.form.fields.electricalHeaterFalse.label"
                      )
                    }}
                  </label>
                </p>
              </div>
            </div>
          </div>
          <div class="field">
            <label for="surface" class="label">
              {{ $t("dashboard.profile.form.fields.surface.label") }}*
            </label>
            <p class="control">
              <input
                name="surface"
                v-model="form.surface"
                class="input"
                type="number"
                :placeholder="
                  $t('dashboard.profile.form.fields.surface.placeholder')
                "
                required
              />
            </p>
          </div>
          <div class="field">
            <label for="person-number" class="label">
              {{ $t("dashboard.profile.form.fields.population.label") }}*
            </label>
            <p class="control">
              <input
                name="person-number"
                v-model="form.population"
                class="input"
                type="number"
                :placeholder="
                  $t('dashboard.profile.form.fields.population.placeholder')
                "
                required
              />
            </p>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'ChangePassword' }">
        {{ $t("dashboard.profile.wantChangePassword") }}
      </router-link>
      <div class="buttons mb-2 p-2 pt-0 is-flex is-justify-content-flex-end">
        <button class="button is-light is-rounded" type="submit">
          {{ $t("global.buttons.reset") }}
        </button>
        <button
          class="button is-rounded is-primary"
          type="submit"
          :class="{ 'is-loading': isLoading }"
        >
          {{ $t("global.buttons.save") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import countries from "@/country.json";

export default {
  name: "Profile",
  data() {
    return {
      countries,
      isLoading: false,
      isUpdated: false,
      isError: false,
      form: {
        firstName: "",
        lastName: "",
        companyName: "",
        country: "",
        email: "",
        phone: "",
        surface: "",
        population: "",
        airConditioning: null,
        electricalHeater: null,
      },
    };
  },
  created() {
    this.getProfileInfos();
  },
  methods: {
    async getProfileInfos() {
      this.isLoading = true;
      await this.$axios
        .get(`/api/profile/`)
        .then((response) => {
          this.form.firstName = response.data.firstName;
          this.form.lastName = response.data.lastName;
          this.form.companyName = response.data.companyName;
          this.form.country = response.data.country;
          this.form.email = response.data.email;
          this.form.phone = response.data.phone;
          this.form.airConditioning = response.data.airConditioning;
          this.form.electricalHeater = response.data.electricalHeater;
          this.form.surface = response.data.surface;
          this.form.population = response.data.population;
        })
        .catch(() => {
          this.isError = true;
        });
      this.isLoading = false;
    },
    async updateProfile() {
      this.isUpdated = false;
      this.isLoading = true;
      await this.$axios
        .put(`/api/profile/`, {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          companyName: this.form.companyName,
          country: this.form.country,
          address: this.form.address,
          email: this.form.email,
          phone: this.form.phone,
          airConditioning: this.form.airConditioning,
          electricalHeater: this.form.electricalHeater,
          surface: this.form.surface,
          population: this.form.population,
        })
        .then(() => {
          this.isUpdated = true;
        })
        .catch(() => {
          this.isError = true;
        });
      this.isLoading = false;
    },
  },
};
</script>
