const modalInitializer = function (overider = {}) {
  return {
    isOpen: false,
    title: "",
    componentName: "",
    initData: {},
    ...overider
  }
}

export {
  modalInitializer
}