<template>
  <div class="consumption-meter is-fullwidth is-fullheight columns">
    <div
      class="
        monitor-container
        custom-box
        column
        is-flex-grow-3
        mt-3
        ml-3
        mb-3
        is-paddingless is-fullwidth
      "
    >
      <h2 class="menu-label p-3">{{ $t("consumptionMonitor.title") }}</h2>
      <p class="p-3">{{ $t("consumptionMonitor.select") }}</p>
      <div class="columns is-fullwidth p-3">
        <div class="column">
          <div class="field">
            <label class="label">{{ $t("consumptionMonitor.categoryLabel") }}</label>
            <div class="select is-fullwidth">
              <select v-model="selectedCategory" :disabled="isLoading">
                <option :value="null">---</option>
                <option
                  v-for="category in categoryOptions"
                  :key="category.value"
                  :value="category.value"
                >
                  {{ category.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">{{ $t("consumptionMonitor.startDateLabel") }}</label>
            <input
              class="input"
              type="date"
              @change="updateStartDate($event)"
              min="1970-01-01"
              max="2100-01-01"
            />
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">{{ $t("consumptionMonitor.endDateLabel") }}</label>
            <input
              class="input"
              type="date"
              @change="updateEndDate($event)"
              min="1970-01-01"
              max="2100-01-01"
            />
          </div>
        </div>
      </div>

      <line-chart
        v-if="!isLoading && !error && selectedCategory !== null"
        id="consumption_monitor"
        class="block mb-4"
        :chart-data="chartData"
        :options="{
          plugins: {legend: {onClick: null}},
          scales: {
            x: {
              title: {display: true, text: 'days'}
            },
            y: {
              beginAtZero: true,
              title: {display: true, text: unit},
            },
          },
          responsive: true,
        }"
      ></line-chart>

      <div v-else-if="isLoading && !error" class="loader" />
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart";
import moment from "moment";

const splice = function (ref, idx, rem, str) {
  return ref.slice(0, idx) + str + ref.slice(idx + Math.abs(rem));
};

export default {
  name: "ComparativeMonitor",
  components: { LineChart },
  data() {
    return {
      selectedCategory: null,
      startDate: null,
      endDate: null,
      isLoading: false,
      error: false,
      chartData: {},
      unit: null,
      categoryOptions: [
        {
          label: this.$t("consumptionMonitor.categories.energy"),
          value: "ENERGY",
          unit: "kWh"
        },
        {
          label: this.$t("consumptionMonitor.categories.waste"),
          value: "WASTE",
          unit: "kg"
        },
        {
          label: this.$t("consumptionMonitor.categories.mobility"),
          value: "MOBILITY",
          unit: "km"
        },
        {
          label: this.$t("consumptionMonitor.categories.water"),
          value: "WATER",
          unit: "m3"
        },
      ],
    };
  },
  watch: {
    selectedCategory(newCategory) {
      this.fetchCategoryIndexes(newCategory);
      this.categoryOptions.forEach(category => {
        if (category.value === newCategory) {
          this.unit = category.unit
        }
      })
    },

    startDate(newStartDate) {
      // avoid huge request ...
      if (newStartDate >= "1970-01-01" && newStartDate <= "2100-01-01")
        this.fetchCategoryIndexes(this.selectedCategory);
    },

    endDate(newEndDate) {
      // avoid huge request ...
      if (newEndDate >= "1970-01-01" && newEndDate <= "2100-01-01")
        this.fetchCategoryIndexes(this.selectedCategory);
    },
  },

  methods: {
    updateStartDate(e) {
      this.startDate = e.target.value;
    },
    updateEndDate(e) {
      this.endDate = e.target.value;
    },
    async fetchCategoryIndexes() {
      this.isLoading = true;
      this.error = false;

      const params = {};

      // avoid huge request ...
      if (
        this.startDate !== null &&
        this.startDate >= "1970-01-01" &&
        this.startDate <= "2100-01-01"
      ) {
        params["start_date"] = this.startDate;
      }

      // avoid huge request ...
      if (
        this.endDate !== null &&
        this.endDate >= "1970-01-01" &&
        this.endDate <= "2100-01-01"
      ) {
        params["end_date"] = this.endDate;
      }

      await this.$axios
        .get(`/api/category/${this.selectedCategory}/indexes/`, { params })
        .then(({ data }) => {
          const minDate = data.startDate;
          const maxDate = data.endDate;
          const labels = [];
          for (
            let m = moment(minDate);
            m.diff(maxDate, "days") <= 0;
            m.add(1, "days")
          ) {
            labels.push(m.format("YYYY-MM-DD"));
          }
          const datasets = [];
          const chartDatas = [];
          Object.keys(data.results).forEach((typeKey) => {
            let date = typeKey;

            date = splice(date, 4, 0, "-");
            date = splice(date, 7, 0, "-");

            chartDatas.push({
              x: date,
              y: data.results[typeKey],
            });
          })

          datasets.push({
            type: "bar",
            data: chartDatas,
            backgroundColor: '#aa1608',
            label: `${this.unit} Daily average consumption`,
          });

          // Only if the references are presents
          if (Object.keys(data.references).length > 0) {
            const referencesDataMax = [];
            const referencesDataMin = [];
            const referencesData = [];
            Object.keys(data.references).forEach((dateKey) => {
              let date = dateKey;
              date = splice(date, 4, 0, "-");
              date = splice(date, 7, 0, "-");
              referencesDataMax.push({
                x: date,
                y: data.references[dateKey] * 1.2, // +20%
              });

              referencesData.push({
                x: date,
                y: data.references[dateKey],
              });

              referencesDataMin.push({
                x: date,
                y: data.references[dateKey] * 0.8, // -20%
              });
            });

            datasets.push({
              data: referencesDataMax,
              borderColor: '#43a542',
              label: `${data.country} average plus 20%`,
            });

            datasets.push({
              data: referencesData,
              borderColor: '#3b594a',
              label: `${data.country} reference`,
            });

            datasets.push({
              data: referencesDataMin,
              borderColor: '#6ae868',
              label: `${data.country} average minus 20%`,
            });
          }

          this.chartData = {
            datasets,
            labels,
          };
        })
        .catch(() => (this.error = true));
      this.isLoading = false;
    },
  },
};
</script>
