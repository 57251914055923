<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="ml-4" href="/">
        <img src="@/assets/logo.png" id="logo">
      </a>
      <a
        role="button"
        class="navbar-burger"
        :class="isNavbarOpen ? 'is-active' : ''"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasic"
        @click="isNavbarOpen = !isNavbarOpen"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasic" :class="[isNavbarOpen ? 'is-active' : '', 'navbar-menu']">
      <div class="navbar-start">
          <a class="navbar-item" v-for="item in items" :key="item.name" :href="item.url">
            {{ $t(item.name) }}
          </a>
        <div class="navbar-item">
          <a class="button is-primary login mr-2" href="/">
            <strong> {{ $t("navbar.registerLogin") }}</strong>
          </a>
        </div>
        <div class="navbar-item">
          <SwitchLanguage />
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <img src="@/assets/erasmus.png" width="150">
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import SwitchLanguage from '@/components/SwitchLanguage.vue'

export default {
  name: "Navbar",
  components: {
    SwitchLanguage
  },
  data() {
    return {
      isNavbarOpen: false,
      items: [
        {
          name: "navbar.echoTourism",
          url: "https://echo-tourism.eu/",
        },
        {
          name: "navbar.thePartners",
          url: "https://echo-tourism.eu/the-partners",
        },
        {
          name: "navbar.becomeAnActor",
          url: "https://echo-tourism.eu/participate-project",
        },
        {
          name: "navbar.newsEvents",
          url: "https://echo-tourism.eu/blog",
        },
        {
          name: "navbar.contactUs",
          url: "https://echo-tourism.eu/contact-us",
        },
      ],
    };
  },
};
</script>
